module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_@babel+core@7.26._5grvcjzedj7fobkpdrykdd2u2q/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"disableVendorPrefixes":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-segment-js@5.0.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+web_rlrbsbbu5aawnsx2qrclljyebi/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"host":"https://cdn.insights.pleo.io","prodKey":"1SribV2gq3K37QKlY2hMY75wQzlbvTF4","devKey":"1SribV2gq3K37QKlY2hMY75wQzlbvTF4","trackPage":false,"manualLoad":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-hubspot@2.0.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+webpac_xvdjkjh7gtfqjvgg2mvjck5id4/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"2694217","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+w_xywkixtw4t5jgkagetshiebk3y/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-TVF6PG8"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+webpack@5.28.5_@swc+core@1.7.39_@s_c7ijmhoqm6vrubuttpyvhcmji4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
